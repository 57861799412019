@import "materialize/materialize";

//Override Materialize library from here on down (to customize it for this application):

//Note: App colors have been changed in _variables.scss and _color.scss.
//If these files are overridden by an updated version of the materialize library, add the following to the files:

//_color.scss:
//$app-colors: (
//        "blue": #006bb6,
//        "light-blue": #0083b6,
//);
//$colors: (
//        "app-colors": $app-colors,
//);

//_variables.scss:
//$primary-color: color("app-colors", "blue") !default;
//$secondary-color: color("app-colors", "blue") !default;
//$card-link-color: $secondary-color !default;

@import "materialNote";

@media #{$large-and-up} {
  header, main, footer {
    padding-left: 240px;
  }
  .nav-wrapper .page-title{
    margin-left:2rem;
  }
}
@media #{$small-and-down} {
  .page-title{
    margin-left:1.2em;
  }
  nav .button-collapse.hamburger{
    position:absolute;
    left:-1em;
  }
  .itemList{
    font-size:16px;
  }
}

.material-icons.vmid{
  vertical-align:-25%;
  margin-right:2rem;
}

.select-wrapper + label{
  transition:none;
}

.collapsible-header i.material-icons{
  width:auto;
}

.collapsible-body {
  padding: 0rem;
}

.side-nav {
  width: 240px;
  left: 0px;
}

.side-nav .collapsible-body li a {
  margin: 0 1rem 0 2em;
  padding-left: 4rem;
}

ul.side-nav.fixed li a {
  font-size: 13px;
  line-height: 44px;
  height: 44px;
}

// Fixed sideNav hide on smaller
@media #{$medium-and-down} {
  .side-nav {
    &.fixed {
      transform: translateX(0%);
    }
  }
}

.bold > a {
  font-weight: bold;
}

nav .page-title{
  font-size: 2.1rem;
  font-weight:200;
}

.nav-wrapper ul li a{
  min-width: 5rem;
  text-align: center;
}

.waves-effect.waves-indigo .waves-ripple {
  /* The alpha value allows the text and background color
  of the button to still show through. */

  background-color: rgba(63,81,181, 0.65);
}

.listItem{
  .row{
    margin-bottom:0;
  }
}

.contextButtons .material-icons{
  display: inline-block;
  vertical-align: top;
}

.container{
  padding-top:2rem;
}

.disabled{
  cursor: not-allowed;
}

ul.bullets{
  list-style-type: initial;
}

.pagination{
  text-align:center;
}

.input-field.richtext{
  margin-top:2rem;
  & label.active{
    transform: translateY(-200%);
  }
}

.note-dialog .modal-content{
  background-color: #ffffff;
}

.note-editable{
  min-height:30vh;
}

.input-group{
  position:relative;
  & input{
    float:left;
    width: calc(100% - 3rem);
  }
  & .btn{
    height:3rem;
    & .material-icons {
      line-height: 3rem;
    }
  }
}

.btn-narrow{
  padding:0;
  width:3rem;
}

.card.medium.dashboardCard{
  .card-content{
    height:100%;
    max-height:100%;
  }
  .giantText{
    font-size:10rem;
    font-weight:200;
    color: color('grey', 'darken-2')
  }
}

.card.horizontal.small {
  //height: 200px;
  min-width: 650px;
}

.card.horizontal.small .card-image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.card.horizontal.small.dashboardCard {
  height: 200px;
  min-width: 0;
}

// Select2 dropdown
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0px;
}

.select2-container--default .select2-selection--single {
  border: 0px;
}

.select2-container {
  border-bottom: 1px solid #9e9e9e;
}

.select2-container--default .select2-selection--multiple {
  border: none;
}

input[type=search]:focus:not([readonly]) {
  border: 0px;
  box-shaddow: 0;

}

input[type=search] {
  height: auto;
}

// Small button

.btn-sm {
  height: 18px;
  line-height: 18px;
  padding: 0 0.5rem;
}

// Pagination links
li.active span {
  font-size: 1.2rem;
  color: #fff;
  display: inline-block;
  padding: 0 10px;
  line-height: 30px;
}

// Datatables
tr.highlight {
  background-color: whitesmoke !important;
  cursor: pointer;
}


// Sandbox filter option on news index
#select2-sandbox-news-filter-container {
  margin-top: 20px;
}

// Index filter button
#user-index-filter-btn {
  margin-top: 25px;
}

iframe {
  width: 800px;
  height: 1500px;
}

// Help Button
.app-help-button {
  position: absolute;
  top: 13px;
  right: 30px;

  .btn-floating i  {
    font-size: 2.4rem;
    color: $error-color;
  }
}

// Show Card
.show-card-img {
  img {
    height: 300px;
    object-fit: cover;
  }
}

.sandbox-card {
  min-width: 600px;
}

.sandbox-details {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

// Collapsible
.collapsible-header {
  .header-row {
    margin-bottom: 0;
  }

  .collapsible-user-name {
    font-size: 3rem;
    i {
      font-size: 3rem;
    }
  }
}

.collapsible-body {
  padding: 1rem;
}

.no-margin-list {
  ul{
    margin: 0;
  }
}

// Dashboard
.byTheNumbersSection {
  margin-top: 40px;
}

.dashboardHeader {
  color: #026BB6;
  margin-bottom: 0;
}

.dashboardDataLabel {
  margin-top: 0;
}